<template>
 <div>
    <b-row>
        <b-col
            cols="12"
        
            lg="8"
            md="7"
        >
            <b-card>
                <b-card-header>
                    <b-card-title>{{ enquiry.code }}</b-card-title>
                </b-card-header>
                <b-card-body>
                    <div>
                        Looking for : {{ enquiry.looking }}<br />
                        {{ enquiry.title }} ({{ enquiry.place_name }})<br />
                        {{ enquiry.bedroom }} Bedroom<br />
                        Budget {{ enquiry.rent_min }} - {{ enquiry.rent_max }}<br />
                        Area : {{ enquiry.zone_name }}
                    </div>
                </b-card-body>
            </b-card>
        </b-col>
        <b-col
            cols="12"
            md="5"
        
            lg="4"
        >
            <card-agent />
            
        </b-col>
    </b-row>
    <card-property />
 </div>   
</template>
<script>
import { ref, onUnmounted } from '@vue/composition-api'

import router from '@/router'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BCardHeader, BCardBody, BCardTitle, BForm, BRow, BCol, BButton,BFormCheckbox,BCard,BBadge,BTable
} from 'bootstrap-vue'
import BCardActions from '../../@core/components/b-card-actions/BCardActions.vue'
import CardAgent from './card/card-agent.vue'
import CardProperty from './card/card-property.vue'
import Ripple from 'vue-ripple-directive'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import store from '@/store'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { methods } from 'vue-echarts'
export default {
    components: {
        ValidationProvider,ValidationObserver,
        BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormCheckbox,BCard,BBadge,BTable,
        BCardActions,
        CardAgent,
        CardProperty,
        BCardBody,BCardHeader,BCardTitle
    },
    filters: {
        getcomma: function(text){
        if(!text){
            return ''

        }
                var nStr = text;
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + ',' + '$2');
                }
                return x1 ;
            },
    },
    data(){
        return {
            enquiry_id:router.currentRoute.params.enquiry_id,
            enquiry:{},
            fields:[
            {
                key: 'code', label: 'Enquiry Code',
            },
            {
                key: 'detail', label: 'Detail',
            },
            {
                key: 'name', label: 'Agency',
            },
           
           
            ],
            properties:[]
        }
    },
    created(){
      this.getEnq();
    },
    methods:{
        async getEnq(){
            var enquiry = await axios.get('enquiry/v1/get/'+this.enquiry_id);
            this.enquiry = enquiry.data.data;
        }
    }
}
</script>
