<template>
  
     
      <b-row>
  
        <!-- image top -->
        <b-col
          md="6"
          xl="4"
          v-for="(value,index) in properties" 
          :key="index"
        >
            
          <b-card
            :img-src="value.property_images[0]?value.property_images[0].fullfilepath:require('@/assets/images/slider/06.jpg')"
            
            img-top
            img-alt="card img"
            :title="value.place_name"
            class="mb-3"
          >
            <b-card-text>
             {{ value.bedroom }} Bedroom
             {{ value.bathroom }} Bathroom
             {{ value.floors }} Floor
             {{ value.sqm }} Sqm
            </b-card-text>
            <b-card-text class="d-flex">
            
              <div>Sale <br />{{ value.sale_price | getcomma }}</div>
              <div>Rent <br />{{ value.rent_price | getcomma }}</div>
            </b-card-text>
            <b-card-text>
              <small class="text-muted">Last updated 3 mins ago</small>
            </b-card-text>
          </b-card>
        </b-col>
  
       
      </b-row>
    
  </template>
  
  <script>
  import {
    BRow, BCol, BCard, BCardText, BCardTitle, BCardBody,
  } from 'bootstrap-vue'
  
import router from '@/router'
import axios from '@axios'
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardText,
      BCardTitle,
      BCardBody
    },
    filters: {
        getcomma: function(text){
        if(!text){
            return ''

        }
                var nStr = text;
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + ',' + '$2');
                }
                return x1 ;
            },
    },
    data(){
        return {
            properties:[]
        };
    },
    created(){
        this.getProperties();
    },
   
    methods:{
        async getProperties(){
            let filter = {
                perpage: 9,
                offset:0
               
            }
            var data = JSON.stringify(filter);
            let properties = await axios.post('cache-master/v1/property-filter', data);
            this.properties = properties.data.data;
            console.log('properties',this.properties);

        }
    }
  }
  </script>
  